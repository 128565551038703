import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import { motion } from 'framer-motion';
import SvgCroix from "../components/svg/croix";

const MentionsPage = ({data}) => {

    return (
        <>
            <SEO title="Mentions" className="max-w-lg mx-auto" />
            <div className="relative flex flex-col w-full items-center justify-center background-blue-edmond">
            <div className="mt-10 pointer-events-none z-130 top-0 flex flex-row items-center justify-between w-10/12 md:w-11/12 py-10">
                        <div className="pointer-events-none w-56 md:w-70">
                        </div>
                        <motion.div whileHover={{ scale: 1.2 }} transition={{ type: "spring" }} className="sofia-light z-40 h-6 md:h-auto mr-10 md:mr-20 right-0 fixed cursor-pointer pointer-events-auto text-5xl" style={{ stroke: "#FFF" }}><Link to="/"><SvgCroix className="w-auto h-full" /></Link></motion.div>
                    </div>
                <MentionsText />
                <div className="fixed bottom-0 right-0 h-full w-full z-20">
              <BackgroundImage
                Tag="section"
                className="w-full h-full"
                fluid={data.mentionImage.childImageSharp.fluid}
                backgroundColor={`#000000`}
              >
              </BackgroundImage>
            </div>
            </div>
        </>
    )
}

export const data = graphql`
    query {
      mentionImage: file(relativePath: { eq: "home/mentions.jpg" }) {
        childImageSharp {
          fluid(quality: 95, maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
    `

const MentionsText = () => (
    <>
        <div className="relative z-30 w-11/12 text-white leading-7">
            <div className="w-full md:w-1/2 text-left mb-10 md:mb-20">
            <h1 className="uppercase text-left mb-8 tracking-wide md:text-2xl xxl:text-4xl sofia-bold">Mentions légales</h1>
            <p className="sofia-regular md:text-sm xxl:text-sm">Conformément aux dispositions des articles 6-III et 19 de la Loi n°2044-575- du 21 juin 2004 pour la confiance dans l’économie numérique, il est porté à la connaissance des utilisateurs et des visiteurs du site COIGNET les mentions légales ci-dessous : Le site COIGNET INVESTMENT MANAGEMENT est accessible à l’adresse suivante : <a href="https://www.coignet.fr" className="underline" target="_blank" rel="noreferrer noopener">www.coignet.fr</a> (ci après Le Site).
L’accès et l’utilisation du Site sont soumis aux présentes mentions légales détaillées ci-après ainsi qu’aux lois et/ou règlements applicables. La connexion, l’utilisation et l’accès à ce Site impliquent l’acceptation intégrale et sans réserve de l’internaute de toutes les dispositions des présentes mentions légales.</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 1 – INFORMATIONS LÉGALES</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Le Site COIGNET INVESTMENT MANAGEMENT est édité par : COIGNET INVESTMENT MANAGEMENT ayant son siège social à l’adresse suivante : 75 rue de Courcelles 75008 Paris et immatriculée au numéro suivant : Paris B 818 464 521
            adresse mail : contact@coignet.fr<br></br>
            ci-après « l’Éditeur » Le Directeur de la publication Guillaume Fekkaï<br></br>
            Adresse mail de contact@coignet.fr ci-après « le directeur de publication »<br></br>
            Conception, réalisation : Tactile<br></br>
            Dont le siège est situé : 20 rue de l’Hôtel de Ville à Neuilly sur Seine (92200)<br></br>
            téléphone : 01 84 20 07 03 <br></br>
                <a href="https://www.agence-tactile.fr" target="_blank" rel="noreferrer noopener" className="underline">www.agence-tactile.fr</a></p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 2 – ACCESSIBILITÉ</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Le Site est par principe accessible aux utilisateurs 24/24h et 7/7j, sauf interruption programmée ou non, pour des besoins de maintenance ou en cas de force majeure. En cas d’impossibilité d’accès au Site, celui-ci s’engage à faire son maximum afin d’en rétablir l’accès. Le Site ne saurait être tenu pour responsable de tout dommage, quelle qu’en soit la nature, résultant de son indisponibilité.</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 3 – COLLECTE DES DONNÉES ET LOI INFORMATIQUE ET LIBERTÉS</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Ce Site est conforme aux dispositions de la Loi 78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et aux libertés. Ainsi, l’Utilisateur bénéficie notamment d’un droit d’opposition (articles 32 et 38), d’accès (articles 38 et 39) et de rectification des données le concernant (article 40). Pour faire usage de ce droit, l’Utilisateur doit s’adresser à l’Éditeur en le contactant par mail à l’adresse suivante : <a href="https://www.coignet.fr" className="underline" target="_blank" rel="noreferrer noopener">www.coignet.fr</a> ou, par courrier adressé au siège social de l’Éditeur en précisant ses coordonnées (nom, prénom, adresse, mail).</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 4 – POLITIQUE DE COOKIES</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Le Site COIGNET INVESTMENT MANAGEMENT a éventuellement recours aux techniques de « cookies » lui permettant de traiter des statistiques et des informations sur le trafic, de faciliter la navigation et d’améliorer le service pour le confort de l’Utilisateur. Conformément à la législation européenne, le Site COIGNET INVESTMENT MANAGEMENT a mis à jour sa politique de confidentialité en matière de cookies. L’Utilisateur est libre d’accepter ou de refuser les cookies de tous sites internet en modifiant les paramètres de son navigateur Internet.</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 5 – LOI APPLICABLE ET JURIDICTION</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Tout litige en relation avec l’utilisation du Site www.coignet.fr est soumis au droit français.</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 6 – COLLECTE ET TRAITEMENT DES DONNÉES</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">COIGNET INVESTMENT MANAGEMENT s’engage à ce que la collecte et le traitement de vos données, effectués à partir du site <a href="https://www.coignet.fr" className="underline" target="_blank" rel="noreferrer noopener">www.coignet.fr</a> soient conformes au Règlement Général de la Protection des Données (RGPD) et à la loi « Informatique et Libertés ».
Les informations recueillies dans le cadre du formulaire de contact sont uniquement transmises par mail au standard de COIGNET INVESTMENT MANAGEMENT afin d’apporter une réponse aux informations que vous demandez. Ces données ne sont pas conservées par COIGNET INVESTMENT MANAGEMENT et n’alimentent aucune base de données.</p>
            <h2 className="uppercase text-left mt-5 mb-2 tracking-wide md:text-lg xxl:text-xl sofia-bold">ARTICLE 7 – DROITS ET LIBERTÉS RELATIFS AU TRAITEMENT DES DONNÉES</h2>
            <p className="sofia-regular md:text-sm xxl:text-sm">Conformément aux dispositions de la loi « Informatique et Libertés » susvisées, toute personne ayant laissé ses coordonnées ou autre information nominative sur le site dispose d’un droit d’accès, de rectification, d’information ou de suppression des données nominatives qui la concernent.
Pour toute information ou exercice de vos droits Informatique et Libertés, vous pouvez contacter le délégué à la protection des données (DPO) de COIGNET INVESTMENT MANAGEMENT : 75 rue de Courcelles 75008 Paris</p>
</div>
        </div>
    </>
)

export default MentionsPage
